<template>
  <ctk-list-filters
    v-if="hasFilters"
    v-model="selectedFilters"
    :label="$t('app.labels.filter')"
    :dialog-title="$t('app.titles.filters')"
    :filters="filters"
    :has-dot="selectedFilters.length > 0"
  />
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapGetters, mapActions } from 'vuex'

  import CtkListFilters from '@/components/CtkListFilters/index.vue'

  import { EventBus } from '@/services/EventBus'

  /**
   * @module component - missionsListHeaderFilters
   */
  export default defineComponent({
    name: 'MissionsListHeaderFilters',
    components: {
      CtkListFilters
    },
    computed: {
      ...mapGetters([
        'isUserDispatcherDriver'
      ]),
      ...mapGetters('missions', [
        'getMissionsFilters'
      ]),
      hasFilters () {
        return Object.keys(this.filters).length
      },
      filters () {
        const inProgressFilters = {
          tracking_to_pickup: this.$t('missions.labels.to_pickup'),
          tracking_to_deliver: this.$t('missions.labels.to_deliver'),
          tracking_to_upload_pod: this.$t('missions.labels.to_upload_pod')
        }

        return {
          ...(this.$route.params.state === 'in_progress' ? inProgressFilters : {})
        }
      },
      selectedFilters: {
        get () {
          const filters = []
          const {
            tracking_to_pickup: toPickup,
            tracking_to_deliver: toDeliver,
            tracking_to_upload_pod: toUploadPod
          } = this.getMissionsFilters

          if (toPickup) filters.push('tracking_to_pickup')
          if (toDeliver) filters.push('tracking_to_deliver')
          if (toUploadPod) filters.push('tracking_to_upload_pod')

          return filters.map(filter => ({
            value: filter
          }))
        },
        set (filters) {
          const availableFilters = Object.keys(this.filters)
          const filtersToDisable = availableFilters
            .filter(filter => !filters.map(f => f.value).includes(filter))

          filtersToDisable.forEach(filter => this.setMissionsFilter({
            filter,
            value: false
          }))

          filters.forEach(filter => this.setMissionsFilter({
            filter: filter.value,
            value: true
          }))

          EventBus.$emit('missions:refresh-list')
        }
      }
    },
    methods: {
      ...mapActions('missions', [
        'setMissionsFilter'
      ])
    }
  })
</script>
