<template>
  <ctk-list-header
    :title="title | capitalize"
    :subtitle="$tc(subtitlePath, getMissionsMeta.item_count, {
      count: $n(getMissionsMeta.item_count)
    })"
    class="missions-list-header tw-px-4 flex-fixed"
  >
    <mission-list-header-filters />
  </ctk-list-header>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapGetters } from 'vuex'

  import MissionListHeaderFilters from './_subs/MissionListHeaderFilters/index.vue'
  import CtkListHeader from '@/components/CtkList/_subs/CtkListHeader/index.vue'

  /**
   * @module component - missionsListHeader
   */
  export default defineComponent({
    name: 'MissionsListHeader',
    components: {
      CtkListHeader,
      MissionListHeaderFilters
    },
    computed: {
      ...mapGetters('missions', [
        'getCurrentMissionStatus',
        'getMissionsMeta'
      ]),
      title () {
        return this.$route.name === 'Mission'
          ? this.$t('app.fields.search')
          : this.$tc(`missions.state.${this.getCurrentMissionStatus}`, 2)
      },
      subtitlePath () {
        return this.$route.name === 'Mission'
          ? 'missions.titles.search.count'
          : 'missions.titles.count'
      }
    }
  })
</script>

<style lang="scss" scoped>
.missions-list-header::after {
  left: 1rem;
  width: calc(100% - 2rem);
}
.missions-list-header .ctk-list-filters {
  margin-top: 1rem;
}
@media only screen and (max-width: $breakpoint-mobile-l) {
  .missions-list-header::v-deep .ctk-list-header__titles {
    padding-left: 2rem;
    margin-bottom: 0;
  }
}
.missions-list-header::v-deep .ctk-list-header__titles__title {
  margin-bottom: 0px;
  font-size: 19px;
}
</style>
