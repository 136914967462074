<template>
  <mission-detail-section
    class="owner-infos"
    data-test="owner-infos"
  >
    <template
      #title
    >
      <mission-detail-section-title
        :title="title"
      >
        <UiCtkIcon
          class="tw-text-blue-500 tw-text-3xl"
          name="team"
          data-test="icon"
        />
      </mission-detail-section-title>
    </template>

    <div class="tw-px-4 tw-py-2 tw-flex tw-flex-col tw-bg-gray-200">
      <div
        class="owner-infos__items tw-flex"
      >
        <mission-detail-group-item
          v-if="hasOwner"
          :title="ownerGroupItemTitle"
          class="owner-infos__item"
          data-test="owner-group-item"
        >
          <template #avatar>
            <ctk-avatar
              :user="owner"
              data-test="owner-avatar"
            />
          </template>
          <template #ui-button>
            <!-- change owner button -->
            <ui-button
              v-if="isChangeOwnerAvailable"
              variant="link"
              size="sm"
              class="edit-owner-btn"
              @click="openChangeOwnerDialog"
            >
              <template #left-icon>
                <ui-ctk-icon
                  name="edit-outlined"
                />
              </template>
            </ui-button>
          </template>
          <div class="tw-flex tw-gap-x-2">
            <div class="tw-flex-1 tw-flex tw-flex-col tw-justify-center tw-break-all">
              <div
                data-test="owner-name"
              >
                {{ ownerFullName }}
              </div>
              <div
                class="owner-email"
                data-test="owner-email"
              >
                {{ owner.email }}
              </div>
            </div>
          </div>
        </mission-detail-group-item>
      </div>
    </div>
  </mission-detail-section>
</template>

<script>
  import { computed, defineComponent, toRefs } from '@vue/composition-api'
  import { EventBus } from '@/services/EventBus'

  import useI18n from '@/composables/useI18n'
  import useStore from '@/composables/useStore'
  import useCapitalizeFilter from '@/composables/filters/useCapitalizeFilter'

  import MissionDetailSection from '@/views/Carriers/Missions/views/_subs/MissionDetail/components/MissionDetailSection/index.vue'
  import MissionDetailSectionTitle from '@/views/Carriers/Missions/views/_subs/MissionDetail/components/MissionDetailSection/MissionDetailSectionTitle/index.vue'
  import MissionDetailGroupItem from '@/views/Carriers/Missions/views/_subs/MissionDetail/components/MissionDetailGroupItem/index.vue'

  import CtkAvatar from '@/components/CtkAvatar/index.vue'

  export default defineComponent({
    name: 'OwnerInfos',
    components: {
      CtkAvatar,
      MissionDetailSection,
      MissionDetailSectionTitle,
      MissionDetailGroupItem
    },
    props: {
      mission: {
        type: Object,
        required: true
      }
    },
    setup (props) {
      const i18n = useI18n()
      const { value: store } = useStore()
      const userInfos = store.getters['auth/getUserInfos']
      const isOwner = store.getters['auth/isCompanyOwner']
      const capitalize = useCapitalizeFilter()

      const { mission } = toRefs(props)

      const owner = computed(() => mission.value?.owner ?? null)
      const hasOwner = computed(() => !!owner.value)
      const ownerFullName = computed(() => capitalize(`${owner.value.first_name ? owner.value.first_name : ''} ${owner.value.last_name ? owner.value.last_name : ''}`))

      const chronotruckContact = computed(() => mission.value ? mission.value.shipper : undefined)

      const isChangeOwnerAvailable = computed(() => {
        return owner.value.uuid === userInfos.uuid || isOwner
      })

      const title = computed(() => capitalize(i18n.value.t('missions.mission_detail.label.team')))
      const ownerGroupItemTitle = computed(() => capitalize(i18n.value.t('missions.mission_detail.label.dispatcher')))

      const openChangeOwnerDialog = () => {
        EventBus.$emit('open-change-owner-dialog')
      }

      return {
        chronotruckContact,
        hasOwner,
        isChangeOwnerAvailable,
        openChangeOwnerDialog,
        owner,
        ownerFullName,
        ownerGroupItemTitle,
        title
      }
    }
  })
</script>

<style lang="scss" scoped>
.owner-infos {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}
.owner-infos__items {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  position: relative;
  grid-gap: 1rem;
  gap: 1rem;
}
.owner-infos__items .mission-detail-group-item {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  -ms-flex-negative: 0;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
}
@media (min-width: 770px) {
  .owner-infos__items .mission-detail-group-item {
    flex: 1 0 calc(18% - 1rem);
  }
}
.owner-infos__item {
  height: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
</style>
