<template>
  <div class="choose-owner-form tw-flex tw-flex-col tw-max-h-full">
    <template>
      <choose-owner-list
        v-model="selectedUser"
        :users="users"
        :users-count="usersCount"
        data-test="list"
      />
    </template>

    <div
      class="choose-owner-form__footer tw-w-full tw-py-3 tw-mt-4"
      data-test="footer"
    >
      <div
        :class="true ? 'tw-justify-end' : 'tw-justify-between'"
        class="footer-buttons tw-flex tw-flex-col-reverse md:tw-flex-row tw-items-center tw-justify-between"
      >
        <ui-button
          class="tw-w-full md:tw-w-auto tw-mt-4 md:tw-mt-0"
          variant="link"
          data-test="cancel-button"
          @click="$emit(summaryOptionsNeeded.length ? 'back' : 'close')"
        >
          {{ (summaryOptionsNeeded.length ? $t('back') : $t('cancel')) | capitalize }}
        </ui-button>

        <ui-button
          id="bookingBtnModal"
          class="tw-w-full md:tw-w-auto"
          variant="primary"
          data-test="validate-button"
          @click="$emit('validate', selectedUser)"
        >
          <slot name="text-button" />
        </ui-button>
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent, ref, toRefs, watch } from '@vue/composition-api'

  import ChooseOwnerList from './_subs/ChooseOwnerList/index.vue'

  export default defineComponent({
    name: 'CtkChooseOwnerForm',
    components: {
      ChooseOwnerList
    },
    props: {
      usersCount: {
        type: Number,
        default: null
      },
      users: {
        type: Array,
        default: Array
      },
      summaryOptionsNeeded: {
        type: Array,
        default: () => ([])
      }
    },
    emits: [
      'validate',
      'back',
      'close'
    ],
    setup (props) {
      const { users } = toRefs(props)

      const selectedUser = ref(null)

      watch(users, () => {
        if (users.value.length === 1) {
          selectedUser.value = users.value[0]
        }
      })

      return {
        selectedUser
      }
    }
  })
</script>
