<template>
  <span class="mission-item-timeslot-date">
    <span class="mission-item-timeslot-date__value">{{ dateLabel }}</span>
  </span>
</template>

<script>
  /**
   * @module component - MissionItemTimeslotDate
   * @param {string} direction
   * @param {boolean} flexible
   */
  export default {
    name: 'MissionItemTimeslotDate',
    props: {
      timeSlot: {
        type: Object,
        required: true
      },
      direction: {
        type: String,
        required: true
      }
    },
    computed: {
      flexible () {
        return this.timeSlot.flexible
      },
      formattedDate () {
        const { date } = this.timeSlot
        return this.$moment(date).format('LL')
      },
      dateLabel () {
        const translationKey = `missions.labels.${this.direction}.date${this.flexible ? '_flexible' : ''}`
        return this.$t(translationKey, { date: this.formattedDate })
      }
    }
  }
</script>

<style lang="scss" scoped>
.mission-item-timeslot-date {
  --tw-text-opacity: 1;
  color: rgba(117, 116, 116, var(--tw-text-opacity));
}
.mission-item-timeslot-date__sign--flexible {
  color: $primary;
}
</style>
