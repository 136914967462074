/**
 * @function useCapitalizeFilter
 * @param {string|null} val
 * @returns {string}
 */
export default () => (val) => {
  if (!val) return ''

  val = val.toString()
  return `${val.charAt(0).toUpperCase()}${val.slice(1)}`
}
