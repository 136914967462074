<template>
  <div
    :class="{
      'should-have-invoices': shouldHaveInvoices
    }"
    class="mission-item-header tw-flex-col"
  >
    <div class="mission-item-header-reference mb-2">
      {{ mission.reference }}
    </div>

    <div class="tw-flex tw-items-center dots-text tw-justify-between">
      <div class="tw-flex tw-flex-wrap tw-w-3/4 tw-justify-start tw-items-center tw-gap-x-10px tw-gap-y-10px">
        <ui-badge
          v-if="!!followUpStatus"
          :neutral="!isCurrent"
          color="red"
        >
          <template #left-icon>
            <ui-ctk-icon
              name="warning"
              data-test="icon"
            />
          </template>
          <span
            class="mission-detail-header-title__badge"
            v-text="`${followUpStatus}`"
          />
        </ui-badge>
        <ui-badge
          v-if="!!badgeText"
          :neutral="!isCurrent"
          :color="badgeColor"
        >
          <template
            #left-icon
            v-if="hasIcon"
          >
            <ui-ctk-icon
              :name="badgeIcon"
              data-test="icon"
            />
          </template>
          <span
            class="mission-detail-header-title__badge"
            v-text="`${badgeText}`"
          />
        </ui-badge>
      </div>

      <div
        class="tw-ml-auto tw-flex tw-self-start tw-justify-start tw-items-center tw-gap-x-2"
      >
        <CtkSubsidiary :subsidiary="mission.bought_by" />
        <CtkAvatar
          :user="mission.owner"
          class="!tw-h-36px !tw-w-36px !tw-leading-36px"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import timeLeftFromNow from '@/services/TimeLeftFromNow'
  import CtkAvatar from '@/components/CtkAvatar/index.vue'
  import CtkSubsidiary from '@/components/CtkSubsidiary/index.vue'

  /**
   * @module component - missionItemHeader
   * @param {object} mission
   * @param {boolean} isLate
   */
  export default defineComponent({
    name: 'MissionItemHeader',
    props: {
      isLate: {
        type: Boolean,
        default: false
      },
      mission: {
        type: Object,
        required: true
      },
      isCurrent: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    components: {
      CtkAvatar,
      CtkSubsidiary
    },
    computed: {
      /**
       * Returns true if the mission is either cancelled or completed
       * @function shouldHaveInvoices
       * @returns {boolean}
       */
      shouldHaveInvoices () {
        const { state } = this.mission
        const states = ['completed', 'cancelled']
        return states.includes(state)
      },
      /**
       * Returns the appropriate title according to the mission state.
       * @function title
       * @returns {string}
       */
      title () {
        const { pickup, delivery } = this.mission
        const isDeliveryLate = delivery.state === 'late'
        const isPickupLate = pickup.state === 'late'

        const isLimitExpired = this.isCmrPending && isDeliveryLate
        const lateDirection = isPickupLate ? 'pickup' : 'delivery'

        // @ts-ignore
        return isLimitExpired
          ? this.$t('missions.tracking_time_limit_expired')
          : this.isLate
            ? this.$t(`missions.mission_item.late_text.${lateDirection}`)
            : this.timeLeftBeforePickup
      },
      /**
       * Returns true if the CMR is pending.
       * @function isCmrPending
       * @returns {boolean}
       */
      isCmrPending () {
        const { proof_of_delivery: POD } = this.mission
        return POD && POD.state === 'pending'
      },
      /**
       * @function pickupTimeLeft
       */
      timeLeftBeforePickup () {
        /**
         * TODO: Simplify this to remove the timeLeftFromNow usage.
         */
        const { date, start_time: startTime } = this.mission.pickup.time_slot
        const { isInPast, fromNow } = timeLeftFromNow(date, startTime)
        return {
          key: isInPast ? 'missions.pickup_begin_in_x_time_passed' : 'missions.pickup_begin_in_x_time',
          time: fromNow
        }
      },
      // badge computed values
      badgeColor () {
        if (this.isCancelled) return 'red'
        if (this.isAwaitingDelivery) return 'green'

        return 'blue'
      },
      badgeText () {
        if (this.isCancelled) return this.$i18n.t('missions.mission_cancelled')
        if (this.isAwaitingBilling) return this.$i18n.t('missions.mission_item.late_text.billing_pending')
        if (this.isAwaitingPOD) return this.$i18n.t('missions.mission_item.late_text.pod_pending')
        if (this.isAwaitingDelivery) return this.$i18n.t('missions.mission_item.late_text.delivery_pending')
        if (this.isAwaitingPickup) {
          return this.isInProgress ? this.$i18n.t('missions.mission_item.late_text.pickup_pending') : this.$i18n.t('missions.pickup_begin_in_x_time', { time: this.pickupTimeLeft })
        }

        if (this.isCompleted) {
          return this.$i18n.t('missions.mission_item.completed')
        }

        return null
      },
      badgeIcon () {
        if (this.isAwaitingPickup) {
          return this.isInProgress ? null : 'info'
        }
      },
      hasIcon () {
        return !!this.badgeIcon
      },
      followUpStatus () {
        const { delivery } = this.mission
        const isDeliveryLate = delivery.state === 'late'
        const isLimitExpired = this.isCmrPending && isDeliveryLate

        // @ts-ignore
        return isLimitExpired
          ? this.$i18n.t('missions.tracking_time_limit_expired')
          : null
      },
      pickupTimeLeft () {
        const { time_slot: timeSlot } = this.mission.pickup
        return this.$moment(`${timeSlot.date} ${timeSlot.start_time}`, 'YYYY-MM-DD HH:mm').fromNow(true)
      },
      isCancelled () {
        return this.mission.state === 'cancelled'
      },
      isPlanned () {
        const { state } = this.mission
        return state === 'planned'
      },
      isInProgress () {
        const { state } = this.mission
        return state === 'in_progress'
      },
      /**
       * Returns true if we're awaiting for pickup
       * @function isAwaitingPickup
       * @returns {boolean}
       */
      isAwaitingPickup () {
        const { pickup } = this.mission
        return ['late', 'pending'].includes(pickup.state)
      },
      /**
       * Returns true if we're awaiting for delivery
       * @function isAwaitingDelivery
       * @returns {boolean}
       */
      isAwaitingDelivery () {
        const { pickup, delivery } = this.mission
        return pickup.state === 'completed' && delivery.state === 'pending'
      },
      /**
       * Returns true if we're awaiting the POD
       * @function isAwaitingPOD
       * @returns {boolean}
       */
      isAwaitingPOD () {
        const { delivery, proof_of_delivery: POD } = this.mission
        const isDeliveryDone = ['late', 'completed'].includes(delivery.state)
        return isDeliveryDone && POD.state === 'pending'
      },
      /**
       * Returns true if the mission is awaiting for billing
       * @function isAwaitingBilling
       * @returns {boolean}
       */
      isAwaitingBilling () {
        const { state, pickup, delivery, proof_of_delivery: POD } = this.mission
        const isCompleted = state === 'completed'
        const isPickupCompleted = pickup.state === 'completed'
        const isDeliveryCompleted = delivery.state === 'completed'

        return !isCompleted &&
          isPickupCompleted &&
          isDeliveryCompleted &&
          POD &&
          POD.state !== 'pending'
      },
      isCompleted () {
        const { state } = this.mission
        return state === 'completed' || this.hasPod
      },
      /**
       * @function hasPod
       * @returns {boolean}
       */
      hasPod () {
        const { proof_of_delivery: pod } = this.mission
        return pod && pod.state === 'available'
      },
      hasInvoices () {
        return this.getBilling.invoices && this.getBilling.invoices.length
      },
      // check if none of its invoices has been paid yet
      hasNoPaidInvoice () {
        if (!this.hasInvoices) {
          return false
        }

        return this.getBilling.invoices.every(invoice => !invoice.payment)
      },
      hasInvoiceNotPaid () {
        let response = false
        if (this.hasInvoices) {
          this.getBilling.invoices.forEach(invoice => {
            if (!invoice.payment) {
              response = true
            }
          })
        }
        return response
      }
    }
  })
</script>

<style lang="scss" scoped>
.mission-item-header {
  padding-bottom: 0.5rem;
}
.mission-item-header-reference {
  --tw-text-opacity: 1;
  color: rgba(183, 183, 183, var(--tw-text-opacity));
}
.mission-item-header i.ctk-font {
  font-size: 30px;
}
.mission-item-header::v-deep .ui-badge--content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
</style>
