<template>
  <div class="billing-infos-contact">
    <div class="billing-infos-contact__infos-contact-warning">
      <h2
        class="billing-infos-contact__infos-contact-warning-title"
        v-text="$t('missions.billing_explanation.infos_contact_warning')"
      />
    </div>
    <div class="billing-infos-contact__invoice-to">
      <h3
        class="billing-infos-contact__invoice-to-title"
        v-text="$t('missions.billing_explanation.invoice_to')"
      />
      <address class="tw-mb-0">
        {{ infos.name }}, {{ infos.location.address }} - {{ infos.location.postalCode }}, {{ infos.location.city }}<br>
      </address>
    </div>
    <div class="billing-infos-contact__email-to">
      <h3
        class="billing-infos-contact__email-to-title"
        v-text="$t('missions.billing_explanation.email_to')"
      />
      <UiLink
        :href="`mailto:${infos.email_billing}`"
        target="_blank"
        data-test="email"
        v-text="infos.email_billing"
      />
    </div>
    <div
      v-if="isBoughtByCeva"
      class="billing-infos-contact__invoice-to-ceva"
    >
      <h3
        class="billing-infos-contact__invoice-to-title-ceva"
        v-text="$t('missions.billing_explanation.postal_delivery')"
      />
      <address
        class="tw-mb-0"
        data-test="invoice-to-address"
      >
        {{ infos.name }}, {{ infos.location.address }} - {{ infos.location.postalCode }}, {{ infos.location.city }}<br>
      </address>
    </div>
  </div>
</template>

<script>
  import chronoinfos from '@/../config/chronoinfos'

  /**
   * @module component - billingInfosContact
   */
  export default {
    name: 'BillingInfosContact',
    data () {
      return {
        chronoInfos: chronoinfos
      }
    },
    props: {
      boughtBy: {
        type: String,
        default: 'chronotruck'
      }
    },
    computed: {
      infos () {
        return this.chronoInfos[this.boughtBy]
      },
      isBoughtByCeva () {
        return this.boughtBy === 'ceva_logistics_france'
      }
    }
  }
</script>

<style lang="scss" scoped>
.billing-infos-contact {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 244, 246, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(244, 244, 246, var(--tw-border-opacity));
  border-radius: 0.25rem;
  border-style: solid;
  border-width: 1px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding: 1rem;
  --tw-text-opacity: 1;
  color: rgba(29, 66, 137, var(--tw-text-opacity));
  grid-gap: 1rem;
  gap: 1rem;
}
.billing-infos-contact__invoice-to-title, .billing-infos-contact__invoice-to-title-ceva, .billing-infos-contact__email-to-title {
  font-size: 0.875rem;
  margin-bottom: 0px;
}
.billing-infos-contact__infos-contact-warning, .billing-infos-contact__infos-contact-warning-title {
  font-size: 0.875rem;
  margin-bottom: 0px;
}
</style>
