<template>
  <div
    v-infinite-scroll="retrieveMoreMissions"
    class="missions-list-content"
    infinite-scroll-distance="120"
  >
    <div class="tw-mb-4">
      <mission-item
        v-if="getMissionUuidToShow"
        :mission="getCurrentMission"
        :key="getCurrentMission.uuid"
        data-test="mission-highlighted"
      />

      <mission-item
        v-for="mission in missions"
        :key="mission.uuid"
        :mission="mission"
        @click.native="setCurrentMission(mission)"
        data-test="mission"
      />
    </div>

    <ctk-infinite-loader
      v-if="!isMissionRoute"
      :loaded-text="$t(`missions.paragraphs.all_loaded.${state}`) | capitalize"
      :load-more-text="$t('missions.buttons.load_more') | capitalize"
      :is-loading="$wait.is('fetching more missions')"
      :can-load-more="canLoadMore"
      :items-count="getStoredMissions.length"
      @load-more="retrieveMoreMissions"
    />
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  import MissionItem from '@/views/Carriers/Missions/components/MissionItem'
  import CtkInfiniteLoader from '@/components/CtkInfiniteLoader'

  /**
   * @module component - missionsListContent
   */
  export default {
    name: 'MissionsListContent',
    components: {
      MissionItem,
      CtkInfiniteLoader
    },
    computed: {
      ...mapGetters('missions', [
        'getMissionUuidToShow',
        'getCurrentMission',
        'getCurrentMissionStatus',
        'getStoredMissions',
        'getMissionsMeta'
      ]),
      /**
       * Returns the route state, if not, the mission state
       * @function state
       */
      state () {
        // eslint-disable-next-line
        return !!this.getMissionUuidToShow
          ? this.getCurrentMissionStatus
          : this.$route.params.state || 'in_progress'
      },
      /**
       * Returns true if we can load more missions
       * @function canLoadMore
       */
      canLoadMore () {
        const { pagination } = this.getMissionsMeta
        return pagination.current_page < pagination.page_count
      },
      /**
       * Returns a list of missions
       * @function missions
       * @returns {Array}
       */
      missions () {
        return this.getStoredMissions.filter(mission => {
          return this.getMissionUuidToShow
            ? mission.uuid !== this.getMissionUuidToShow
            : true
        })
      },
      isMissionRoute () {
        return this.$route.name === 'Mission'
      }
    },
    methods: {
      ...mapActions('missions', [
        'setCurrentMission',
        'getMoreMissions'
      ]),
      /**
       * @function retrieveMoreMissions
       */
      retrieveMoreMissions () {
        this.$wait.start('fetching more missions')
        this.getMoreMissions({
          status: this.getCurrentMissionStatus,
          limit: 30
        }).finally(() => {
          this.$wait.end('fetching more missions')
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
.missions-list-content {
  -ms-flex-negative: 0;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  overflow-y: auto;
}
</style>
