<template>
  <div class="mission-detail-specificity-item tw-flex tw-border tw-border-solid tw-border-gray-500 tw-py-3">
    <div class="tw-flex-shrink-0">
      <ui-ctk-icon
        :name="icon"
        class="tw-w-4 tw-ml-2 tw-mr-1 tw-text-blue-500"
        data-test="icon"
      />
    </div>
    <div class="tw-flex-1 tw-pr-4">
      <div class="tw-inline-flex tw-flex-wrap tw-items-center">
        <div
          v-text="title"
          class="tw-uppercase tw-text-secondary-text tw-text-xs tw-mr-1 tw-font-normal"
          data-test="title"
        />
      </div>
      <div
        class="tw-font-medium tw-break-words tw-whitespace-pre-wrap tw-text-sm tw-mb-0"
      >
        <slot name="content">
          <p
            v-text="content"
            class="tw-mb-0"
            data-test="body"
          />
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  export default defineComponent({
    name: 'MissionDetailSpecificityItem',
    props: {
      icon: {
        type: String,
        required: true
      },
      title: {
        type: String,
        required: true
      },
      content: {
        type: String,
        default: null
      },
      variant: {
        type: String,
        default: 'info'
      }
    }
  })
</script>
