<template>
  <div class="missions-aside-layout">
    <div class="missions-aside-layout__content">
      <slot name="header" />

      <!-- Content -->
      <div class="missions-aside-layout__content__body">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  export default defineComponent({
    name: 'MissionsAsideLayout'
  })
</script>

<style lang="scss" scoped>
.missions-aside-layout {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 249, 249, var(--tw-bg-opacity));
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.missions-aside-layout__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0;
  -webkit-flex: 1 1 0;
  flex: 1 1 0;
  min-width: 0px;
  overflow-y: auto;
  position: relative;
  z-index: 0;
}
.missions-aside-layout__content__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0;
  -webkit-flex: 1 1 0;
  flex: 1 1 0;
  padding-left: 1rem;
  padding-right: 1rem;
}
</style>
