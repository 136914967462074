<template>
  <div class="mission-detail-group-item tw-flex tw-flex-col tw-max-w-fit">
    <div class="tw-flex tw-justify-between">
      <div class="tw-flex tw-gap-2  tw-items-center">
        <slot name="avatar" />
        <div
          v-text="title"
          class="mission-detail-group-item-title tw-uppercase tw-text-xs tw-text-secondary-text tw-mb-1 tw-font-normal"
          data-test="title"
        />
      </div>
      <slot name="ui-button" />
    </div>
    <div
      :class="{
        'tw-text-blue-500': active,
        'tw-text-secondary-lighten': !active,
      }"
      class="tw-font-medium tw-text-sm"
      data-test="content"
    >
      <slot />
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import CtkAvatar from '@/components/CtkAvatar/index.vue'

  /**
   * @module component - MissionDetailGroupItem
   * @param {string} title
   */
  export default defineComponent({
    name: 'MissionDetailGroupItem',
    components: { CtkAvatar },
    props: {
      title: {
        type: String,
        required: true
      },
      active: {
        type: Boolean,
        default: false
      }
    }
  })
</script>
