<template>
  <mission-detail-section
    class="mission-detail-pricing-section tw-flex tw-flex-col"
  >
    <template
      #title
    >
      <mission-detail-section-title
        :title="$t('missions.fare')"
      >
        <ui-ctk-icon
          name="budget"
          class="tw-text-blue-500 tw-text-3xl"
        />
      </mission-detail-section-title>
    </template>

    <template>
      <!-- Fare -->
      <div
        class="tw-flex tw-items-center tw-flex-1 tw-justify-between tw-mb-2"
        data-test="fare"
      >
        <span
          class="tw-my-auto tw-mx-0 tw-uppercase tw-text-gray-700"
          v-text="$t('app.pricing.your_fare_without_tax')"
          data-test="label"
        />
        <price-chip
          class="price-value tw-py-1 tw-py-2 tw-mr-0 tw-my-auto tw-ml-4 tw-text-base"
          data-test="price"
        >
          {{ $t('missions.price_excl_tax', { price: $options.filters.currency(getPriceExclTax, billing.currency, $i18n.locale) }) }}
        </price-chip>
      </div>
    </template>
  </mission-detail-section>
</template>

<script>
  import { defineComponent, computed, toRefs } from '@vue/composition-api'

  import MissionDetailSection from '../../../../components/MissionDetailSection/index.vue'
  import MissionDetailSectionTitle from '../../../../components/MissionDetailSection/MissionDetailSectionTitle/index.vue'
  import PricingLayout from '@/components/CtkPricing/_subs/PricingLayout.vue'
  import PricingLines from '@/components/CtkPricing/_subs/PricingLines/index.vue'
  import PriceChip from '@/components/CtkPricing/_subs/PriceTotal/_subs/PriceChip/index.vue'

  /**
   * @module component - MissionDetailPricingSection
   */
  export default defineComponent({
    name: 'MissionDetailPricingSection',
    components: {
      MissionDetailSection,
      MissionDetailSectionTitle,
      PricingLayout,
      PricingLines,
      PriceChip
    },
    props: {
      billing: {
        type: Object,
        required: true
      }
    },
    setup (props) {
      const { billing } = toRefs(props)

      /**
       * Returns only the travel line
       */
      const fareLines = computed(() => {
        return billing.value.lines
          // @ts-ignore
          .filter(line => line.key === 'travel')
      })

      /**
       * Returns all the price lines except the travel one, which is already
       * included in the fare lines.
       */
      const specificitesLines = computed(() => {
        return billing.value.lines
          // @ts-ignore
          .filter(line => line.key !== 'travel')
      })

      const getPriceExclTax = computed(() => {
        return billing.value.invoices_amount ?? billing.value.price
      })

      return {
        fareLines,
        specificitesLines,
        getPriceExclTax
      }
    }
  })
</script>

<style lang="scss">
.mission-detail-pricing-section .pricing-layout {
  padding-left: 0px;
  padding-right: 0px;
}
.mission-detail-pricing-section .pricing-lines {
  border-style: none;
  padding-top: 0px;
  padding-bottom: 0px;
}
.mission-detail-pricing-section .pricing-lines-label {
  --tw-text-opacity: 1;
  color: rgba(29, 66, 137, var(--tw-text-opacity));
}
</style>
