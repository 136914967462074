<template>
  <div
    class="mission-item-direction tw-flex tw-flex-col tw-flex-1 pr-2"
  >
    <ctk-address
      v-for="direction in ['pickup', 'delivery']"
      :key="direction"
      :has-street-name="false"
      :address="mission[direction].address"
      class="tw-flex dots-text tw-flex-1"
    >
      <template #date>
        <mission-item-timeslot-date
          :key="`${direction}-timeslot-date`"
          :time-slot="mission[direction].time_slot"
          :direction="direction"
          class="tw-font-normal tw--mt-4px dots-text"
        />
      </template>
    </ctk-address>
  </div>
</template>

<script>
  import CtkAddress from '@/components/CtkAddress'
  import MissionItemTimeslotDate from '@/views/Carriers/Missions/components/MissionItem/_subs/MissionItemTimeslotDate'

  /**
   * @module component - missionItemDirection
   * @param {object} mission
   */
  export default {
    name: 'MissionItemDirection',
    components: {
      CtkAddress,
      MissionItemTimeslotDate
    },
    props: {
      mission: {
        type: Object,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>
.mission-item-direction {
  position: relative;
  min-width: 0;
}
.mission-item-direction::after {
  position: absolute;
  content: '';
  background-image: url('~@/assets/img/division-line.svg');
  height: 31px;
  width: 1px;
  left: 9px;
  top: 23px;
}
.mission-item-direction::v-deep .ctk-address__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
</style>
