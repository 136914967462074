<template>
  <button
    :title="owner.fullName"
    :class="{
      'choose-owner-list-item--active': active,
    }"
    type="button"
    class="choose-owner-list-item tw-flex-shrink-0 tw-flex tw-items-center tw-bg-white tw-px-3 tw-py-2 tw-text-left tw-transition-colors tw-border tw-border-solid tw-border-gray-500"
  >
    <div class="tw-flex tw-flex-1">
      <ctk-avatar
        :user="owner"
        class="tw-flex-shrink-0 tw-mr-3 tw-h-8"
        data-test="avatar"
      />

      <div class="tw-flex-1 tw-flex tw-flex-col tw-justify-center tw-leading-tight tw-truncate">
        <span
          class="tw-m-0 tw-truncate tw-text-secondary"
          v-text="owner.fullName"
          data-test="name"
        />
        <span
          v-if="owner.phone || owner.email"
          v-text="owner.phone
            ? $options.filters.telephone(owner.phone)
            : owner.email"
          class="tw-text-xs tw-text-secondary-text"
          data-test="content"
        />
      </div>
    </div>

    <ui-material-icon
      :name="active ? 'check_circle' : 'radio_button_unchecked'"
      class="choose-owner-list-item__radio tw-text-xl tw-ml-4 tw-text-gray-500 tw-transition-colors"
      data-test="radio"
    />
  </button>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import CtkAvatar from '@/components/CtkAvatar/index.vue'

  /**
   * @module component - ChooseOwnerListItem
   * @param {object} owner
   */
  export default defineComponent({
    name: 'ChooseOwnerListItem',
    components: {
      CtkAvatar
    },
    props: {
      owner: {
        type: Object,
        required: true
      },
      active: {
        type: Boolean,
        default: false
      }
    }
  })
</script>

<style lang="scss" scoped>
.choose-owner-list-item {
  min-height: 42px;
}
.choose-owner-list-item--active:not(:disabled), .choose-owner-list-item:focus:not(:disabled), .choose-owner-list-item.aria-focus-active {
  --tw-border-opacity: 1;
  border-color: rgba(39, 84, 145, var(--tw-border-opacity));
  border-style: solid;
  border-width: 1px;
}
.choose-owner-list-item--active:not(:disabled) .choose-owner-list-item__radio {
  --tw-text-opacity: 1;
  color: rgba(39, 84, 145, var(--tw-text-opacity));
}
.choose-owner-list-item:hover:not(:disabled) {
  background-color: $light-gray;
}
.choose-owner-list-item:hover:not(:disabled):not(.choose-owner-list-item--active) .choose-owner-list-item__radio, .choose-owner-list-item:disabled .choose-owner-list-item__radio {
  --tw-text-opacity: 1;
  color: rgba(117, 116, 116, var(--tw-text-opacity));
}
.choose-owner-list-item:disabled {
  cursor: not-allowed;
  background-color: rgba(black, 0.05);
  color: rgba(0, 0, 0, 0.54);
}
.choose-owner-list-item:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.choose-owner-list-item .ctk-avatar {
  height: 2rem;
  line-height: 2rem;
  width: 2rem;
}
</style>
