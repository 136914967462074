<template>
  <div class="mission-unselected">
    <div class="mission-unselected-icon">
      <img
        src="@/assets/img/icons/search.svg"
        alt=""
        width="80"
        height="80"
        class="tw-mb-4"
        data-test="icon"
      >
      <h2
        v-text="title"
        class="mission-unselected-title"
        data-test="title"
      />
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  export default defineComponent({
    name: 'MissionUnselected',
    props: {
      title: {
        type: String,
        required: true
      }
    }
  })
</script>

<style lang="scss" scoped>
.mission-unselected-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin: auto;
  text-align: center;
}
.mission-unselected-title {
  font-weight: 400;
  font-size: 1.25rem;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 770px) {
  .mission-unselected-title {
    width: 66.666667%;
  }
}
</style>
