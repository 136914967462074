<template>
  <mission-detail-group-item
    v-if="hasContactInfos"
    :title="$t('app.labels.address.contact_informations')"
    class="mission-detail-contact-infos tw-w-full"
    data-test="contact-infos"
  >
    {{ contactInfos }}
  </mission-detail-group-item>
</template>

<script>
  import { computed, defineComponent, toRefs } from '@vue/composition-api'
  import usePhoneFilter from '@/composables/filters/usePhoneFilter'

  import MissionDetailSection from '@/views/Carriers/Missions/views/_subs/MissionDetail/components/MissionDetailSection/index.vue'
  import MissionDetailSectionTitle from '@/views/Carriers/Missions/views/_subs/MissionDetail/components/MissionDetailSection/MissionDetailSectionTitle/index.vue'
  import MissionDetailGroupItem from '@/views/Carriers/Missions/views/_subs/MissionDetail/components/MissionDetailGroupItem/index.vue'

  /**
   * @module component - ContactInfos
   */
  export default defineComponent({
    name: 'ContactInfos',
    components: {
      MissionDetailSection,
      MissionDetailSectionTitle,
      MissionDetailGroupItem
    },
    props: {
      mission: {
        type: Object,
        required: true
      },
      direction: {
        type: String,
        required: false,
        default: null
      }
    },
    setup (props) {
      const { mission, direction } = toRefs(props)
      const phoneFormat = usePhoneFilter()

      const hasContactInfos = computed(() =>
        !!mission &&
        !!direction &&
        !!mission.value &&
        !!direction.value &&
        !!mission.value[direction.value] &&
        !!Object.keys(mission.value[direction.value]).length &&
        !!mission.value[direction.value].contact &&
        !!Object.keys(mission.value[direction.value].contact).length
      )

      const contactInfos = computed(() => {
        if (!hasContactInfos.value) return ''

        const { contact: { name, phone } } = mission.value[direction.value]

        return `${name || ''} ${phone ? phoneFormat(phone) : ''}`
      })

      return {
        hasContactInfos,
        contactInfos
      }
    }
  })
</script>

<style lang="scss" scoped>
.mission-detail-contact-infos {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  margin-bottom: 44px;
  white-space: nowrap;
}
.mission-detail-contact-infos__items {
  margin: -1rem;
}
.mission-detail-contact-infos__items .mission-detail-group-item {
  margin-bottom: 0.5rem;
  margin: 1rem;
  flex: 1 0 calc(45% - 1rem);
}
@media (min-width: 770px) {
  .mission-detail-contact-infos__items .mission-detail-group-item {
    flex: 1 0 calc(18% - 1rem);
  }
  .mission-detail-contact-infos__items .mission-detail-group-item:not(:last-child) {
    position: relative;
  }
  .mission-detail-contact-infos__items .mission-detail-group-item:not(:last-child)::after {
    --tw-border-opacity: 1;
    border-color: rgba(214, 214, 218, var(--tw-border-opacity));
    border-style: solid;
    border-top-width: 1px;
    border-left-width: 1px;
    border-bottom-width: 1px;
    border-right-width: 0px;
    height: 100%;
    position: absolute;
    content: '';
    width: 1px;
    right: -1rem;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}
</style>
