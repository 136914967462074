<template>
  <div class="choose-owner-list">
    <div
      v-if="usersCount > 6"
      class="tw-pb-3 tw-flex-shrink-0"
      data-test="search"
    >
      <ctk-input-text
        id="search-owner"
        v-model="query"
        :label="$t('search_a_owner')"
        ignore-optional
        type="search"
        name="search"
        data-test="search-field"
      />
    </div>
    <div
      class="choose-owner-list__items tw-flex-1 tw-flex tw-flex-col tw-relative tw-overflow-auto"
      data-test="list"
    >
      <choose-owner-list-item
        v-for="user in filteredUsers"
        :key="user.uuid"
        :owner="user"
        :active="selectedUser === user"
        class="tw-w-full tw-mb-3"
        @click.native="selectedUser = user"
      />
      <div
        v-if="filteredUsers.length === 0"
        key="empty"
        class="tw-p-4"
        data-test="empty-results"
      >
        <p
          class="tw-text-gray-600 tw-text-center tw-m-0"
          data-test="empty-results-message"
          v-text="query
            ? $t('no_owner_available_with_x', { query })
            : $t('app.labels.no_users')"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { computed, defineComponent, ref, toRefs } from '@vue/composition-api'

  import SmartSearch from '@/services/SmartSearch'
  import useModelGetterSetter from '@/composables/useModelGetterSetter'
  import CtkInputText from '@/components/CtkInputs/CtkInputText/index.vue'
  import ChooseOwnerListItem from './_subs/ChooseOwnerListItem/index.vue'

  /**
   * @module component - ChooseOwnerList
   */
  export default defineComponent({
    name: 'ChooseOwnerList',
    components: {
      CtkInputText,
      ChooseOwnerListItem
    },
    props: {
      value: {
        type: Object,
        default: null
      },
      users: {
        type: Array,
        default: () => ([])
      },
      usersCount: {
        type: Number,
        default: 0
      }
    },
    setup (props) {
      const { users } = toRefs(props)
      const query = ref(null)
      const { state: selectedUser } = useModelGetterSetter(props, 'value')

      const filteredUsers = computed(() => {
        const q = (query.value || '').trim()
        // @ts-ignore
        const entries = users.value.map(user => ({
          name: user.fullName,
          uuid: user.uuid
        }))

        const fields = ['name']
        const results = SmartSearch(entries, [q], fields)

        if (q === '' || query === null) {
          return users.value
        }

        // @ts-ignore
        return users.value.filter(user => results
          .findIndex(resultUser => resultUser.entry.uuid === user.uuid) !== -1)
      })

      return {
        selectedUser,
        query,
        filteredUsers
      }
    }
  })
</script>
