<template>
  <div class="missions">
    <ctk-side-bar
      ref="searchSideBar"
      :width="350"
      :open="isSidebarOpen"
      class="missions-menu tw-flex tw-flex-col"
      @toggle-menu="toggleSidebar"
    >
      <mission-sections
        style="padding-bottom: 100px;"
      />
    </ctk-side-bar>

    <RouterView
      :class="{
        'has-menu-sidebar': isSidebarOpen,
      }"
      class="main-container"
    />
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import store from '@/store'

  import CtkSideBar from '@/components/CtkSideBar/index.vue'
  import MissionSections from './views/_subs/MissionSections.vue'

  export default {
    name: 'MissionsRouter',
    components: {
      MissionSections,
      CtkSideBar
    },
    computed: {
      ...mapGetters('ui', [
        'isSidebarOpen'
      ])
    },
    methods: {
      ...mapActions('ui', [
        'toggleSidebar',
        'setSidebarOpen'
      ])
    },
    metaInfo () {
      return {
        title: this.$t('missions.title')
      }
    },
    beforeRouteEnter (to, from, next) {
      if (store.getters.isUserShipper) {
        next({
          name: 'Dashboard'
        })
        return
      }

      next()
    }
  }
</script>

<style lang="scss" scoped>
.missions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0;
  -webkit-flex: 1 1 0;
  flex: 1 1 0;
}
.missions .main-container {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0;
  -webkit-flex: 1 1 0;
  flex: 1 1 0;
  transition: padding-left 300ms ease-in-out;
}
@media (min-width: 770px) {
  .missions .main-container:not(.has-menu-sidebar) {
    padding-left: 2rem;
  }
}
</style>
