<script>
  import { defineComponent } from '@vue/composition-api'

  export default defineComponent({
    name: 'MissionDetailSpecificityGroup',
    data () {
      return {
        hasSlots: false,
        hasAccessibility: false
      }
    },
    methods: {
      computeSlots () {
        const componentSlots = this.$slots.default && this.$slots.default
          .filter(component => {
            /**
             * Remove any element that's not a component (unmounted comp, text, comments).
             */
            const { componentOptions } = component
            if (!componentOptions || !componentOptions.tag) return false

            /**
             * Excludes components that have no content. Eg. accessibility component with a
             * root v-if.
             */
            return true
          })

        this.hasSlots = !!componentSlots && componentSlots
          .filter(component => component?.componentOptions?.tag !== 'mission-detail-accessibility')
          .length > 0

        // @ts-ignore
        this.hasAccessibility = !!componentSlots && !!componentSlots[0] && componentSlots[0].componentOptions?.tag === 'mission-detail-accessibility' && !!componentSlots[0].componentInstance && !!componentSlots[0].componentInstance.hasAccessibilityIssue
      }
    },
    mounted () {
      this.computeSlots()
    },
    updated () {
      this.computeSlots()
    },
    render (createElement) {
      return createElement('div', {
        class: {
          'mission-detail-specificity-group--arrow': this.hasSlots && !this.hasAccessibility,
          'tw-mb-44px': this.hasSlots || this.hasAccessibility,
          'mission-detail-specificity-group tw-relative tw-flex tw-flex-col tw-mt-3': true
        }
      }, [
        this.$slots.default
      ])
    }
  })
</script>

<style lang="scss">
.mission-detail-specificity-group--arrow::after {
  --tw-border-opacity: 1;
  border-color: rgba(214, 214, 218, var(--tw-border-opacity));
  position: absolute;
  content: '';
  width: 1rem;
  height: 1rem;
  top: -0.5rem;
  left: 2rem;
  background: white;
  border-width: 1px 0 0 1px;
  border-style: solid;
  transform: rotate(45deg);
}
.mission-detail-specificity-group .mission-detail-accessibility:not(:first-child)::before {
  display: none;
}
.mission-detail-specificity-group > div:not(:last-child) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: none;
}
.mission-detail-specificity-group > div:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
