























const tooltipPlacements = [
  'auto', 'top', 'bottom', 'left', 'right', 'topleft', 'topright', 'bottomleft', 'bottomright', 'lefttop', 'leftbottom', 'righttop', 'rightbottom'
]

const fontSizes = [
  'xs', 'sm', 'base', 'lg', 'xl', '2xl', '3xl', '4xl', '5xl', '6xl', '7xl', '8xl'
]

export default {
  name: 'ProofOfDeliveryUploadHelpButton',
  props: {
    icon: {
      type: String,
      required: false,
      default: null
    },
    textPath: {
      type: String,
      required: false,
      default: null
    },
    rawText: {
      type: String,
      required: false,
      default: null
    },
    textPosition: {
      type: String,
      required: false,
      default: null,
      validator (value) {
        return ['left', 'right', 'before', 'after', 'normal', 'reverse'].includes(value)
      }
    },
    tooltipPath: {
      type: String,
      required: false,
      default: null
    },
    titlePath: {
      type: String,
      required: false,
      default: null
    },
    color: {
      type: String,
      required: false,
      default: 'blue-500'
    },
    hoverColor: {
      type: String,
      required: false,
      default: 'blue-600'
    },
    fontSize: {
      type: String,
      required: false,
      default: '35px',
      validator (value) {
        return fontSizes.includes(value) || /^\d+px$/g.test(value)
      }
    },
    placement: {
      type: String,
      required: false,
      default: 'auto',
      validator (value) {
        return tooltipPlacements.includes(value)
      }
    }
  },
  computed: {
    title () {
      return this.$t(this.titlePath)
    },
    text () {
      return this.textPath ? this.$t(this.textPath) : this.rawText ? this.rawText : null
    },
    tooltipOptions () {
      return {
        title: this.$t(this.tooltipPath),
        target: 'help-button',
        placement: 'placement',
        boundary: 'window'
      }
    }
  }
}
