<template>
  <mission-detail-section
    class="billing-infos tw-flex tw-flex-col"
  >
    <template
      #title
    >
      <mission-detail-section-title
        :title="$t('missions.detail.tab.billing')"
      >
        <ui-ctk-icon
          name="invoice"
          class="tw-text-blue-500 tw-text-xl"
        />
      </mission-detail-section-title>
    </template>

    <template>
      <!-- Specific for cancelled state -->
      <pricing-layout
        v-if="isCancelled"
        class="billing-infos-explanation billing-infos-cancelled"
        data-test="billing-infos-cancelled-explanation"
      >
        <!-- Fare -->
        <div
          v-if="getBilling"
          class="tw-flex tw-items-center tw-flex-1 tw-justify-between tw-mb-4"
          data-test="fare"
        >
          <span
            class="tw-my-auto tw-mx-0 tw-uppercase tw-text-gray-700"
            v-text="$t('app.pricing.your_fare_without_tax')"
            data-test="label"
          />
          <price-chip
            class="price-value tw-py-1 tw-py-2 tw-mr-0 tw-my-auto tw-ml-4 tw-text-base"
            data-test="price"
          >
            {{ $options.filters.currency(billing.price, billing.currency, $i18n.locale, true) }}
          </price-chip>
        </div>

        <div class="tw-mb-4">
          <span
            v-text="$t('missions.billing_text.mission_cancelled')"
            data-test="text-cancelled-mission"
          />
        </div>
      </pricing-layout>

      <!-- Displayed on all States -->
      <pricing-layout
        class="billing-infos-explanation billing-infos-pending"
        data-test="billing-infos-explanation"
      >
        <div class="tw-flex tw-flex-col tw-justify-center tw-gap-4 tw-flex-grow md:tw-max-w-1/2">
          <p
            v-text="$t('missions.billing_explanation_text_without_pod')"
            class="tw-mt-1"
            data-test="billing-infos-completed"
          />

          <p
            v-text="$t('missions.billing_explanation.pod')"
            class="tw-mt-1"
            data-test="billing-infos-completed-pod"
          />

          <p
            v-text="$t('missions.billing_explanation.list_title')"
            class="tw-mt-1 tw-mb-0"
            data-test="billing-infos-explanations-list"
          />

          <ul class="tw-pl-4">
            <li
              v-text="$t('missions.billing_explanation.list_first_element')"
              data-test="billing-infos-explanations-list-1"
            />
            <li
              v-text="$t('missions.billing_explanation.list_second_element')"
              data-test="billing-infos-explanations-list-2"
            />
            <li
              v-if="isMissionBoughtByCeva"
              v-text="$t('missions.billing_explanation.list_third_element')"
              data-test="billing-infos-explanations-list-3"
            />
          </ul>
        </div>
        <div class="tw-flex tw-flex-col tw-justify-start tw-gap-4 tw-flex-grow md:tw-max-w-1/2">
          <billing-infos-contact :bought-by="getCurrentMission.bought_by" />
        </div>
      </pricing-layout>
    </template>
  </mission-detail-section>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { defineComponent } from '@vue/composition-api'

  import chronoinfos from '@/../config/chronoinfos'

  import MissionDetailSection from '@/views/Carriers/Missions/views/_subs/MissionDetail/components/MissionDetailSection'
  import MissionDetailSectionTitle from '@/views/Carriers/Missions/views/_subs/MissionDetail/components/MissionDetailSection/MissionDetailSectionTitle'
  import PriceChip from '@/components/CtkPricing/_subs/PriceTotal/_subs/PriceChip/index.vue'

  import PricingLayout from '@/components/CtkPricing/_subs/PricingLayout.vue'
  import BillingInfosContact from '@/views/Carriers/Missions/views/_subs/MissionDetail/_subs/BillingInfos/_subs/BillingInfosContact/index.vue'

  /**
   * @module component - billingInfos
   * @param {object} billing
   */
  export default defineComponent({
    name: 'BillingInfos',
    components: {
      BillingInfosContact,
      PricingLayout,
      MissionDetailSection,
      MissionDetailSectionTitle,
      PriceChip
    },
    props: {
      billing: {
        type: Object,
        default: Object
      }
    },
    data () {
      return {
        chronoInfos: chronoinfos,
        details: false
      }
    },
    computed: {
      ...mapGetters('missions', [
        'getCurrentMission',
        'getBilling'
      ]),
      isCancelled () {
        return this.getCurrentMission.state === 'cancelled'
      },
      /**
       * Returns true if every invoice in the billing is paid
       * @function hasEveryPaidInvoices
       * @returns {boolean}
       */
      hasEveryPaidInvoices () {
        const invoices = this.billing ? this.billing.invoices : null
        if (!invoices) return false
        return invoices.length > 0 && invoices.every(invoice => invoice.state === 'paid')
      },
      isMissionBoughtByCeva () {
        return this.getCurrentMission.bought_by === 'ceva_logistics_france'
      }
    }
  })
</script>

<style lang="scss" scoped>
.billing-infos {
  padding-top: 36px;
  padding-bottom: 36px;
}
.billing-infos-explanation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  padding-left: 0px;
  padding-right: 0px;
  line-height: 1.3;
}
.billing-infos-explanation.billing-infos-cancelled {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  grid-gap: 0px;
  gap: 0px;
}
@media (min-width: 770px) {
  .billing-infos-explanation {
    grid-gap: 2rem;
    gap: 2rem;
  }
}
.billing-infos .title {
  color: #505050;
}
</style>
