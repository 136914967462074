<template>
  <ctk-aside-dialog
    v-model="modalState"
    modal-class="change-mission-owner-dialog"
    :title="$t('missions.reallocation_owner')"
  >
    <div
      class="tw-p-4 md:tw-px-10 md:tw-pb-10 md:tw-pt-28 tw-h-full tw-flex tw-flex-col"
    >
      <tabs-bar
        v-model="currentTab"
        :tabs="tabs"
        class="tw-mb-6"
        data-test="tabs"
      />

      <template>
        <ctk-choose-owner-form
          :users-count="usersCount"
          :users="users"
          class="tw-w-full tw-flex-1"
          @validate="changeOwner"
          @cancel="close"
          @close="close"
        >
          <template #text-button>
            <span>
              {{ $t('missions.reallocation_to_this_owner') | capitalize }}
            </span>
          </template>
        </ctk-choose-owner-form>
      </template>
    </div>
  </ctk-aside-dialog>
</template>

<script>
  import { computed, defineComponent, onMounted, ref, watch } from '@vue/composition-api'

  import useModelGetterSetter from '@/composables/useModelGetterSetter'

  import CtkAsideDialog from '@/components/CtkAsideDialog/index.vue'
  import CtkAddOwnerForm from '@/components/CtkAddOwnerForm/index.vue'
  import CtkChooseOwnerForm from '@/components/CtkChooseOwnerForm/index.vue'
  import { TabsBar } from '@/components/CtkTabsLayout'

  import useStore from '@/composables/useStore'
  import useWait from '@/composables/useWait'

  import { User as UserResource, CompanyMission } from '@/resources'
  import { showToaster } from '@/services/Toaster'
  import User from '@/models/User'
  import { i18n } from '@/locales'

  /**
   * @module component - ChangeOwnerDialog
   */
  export default defineComponent({
    name: 'ChangeOwnerDialog',
    components: {
      CtkAsideDialog,
      CtkAddOwnerForm,
      CtkChooseOwnerForm,
      TabsBar
    },
    emits: [
      'value'
    ],
    model: {
      prop: 'value'
    },
    props: {
      value: {
        type: Boolean,
        required: true
      }
    },
    setup (props) {
      const { state: modalState } = useModelGetterSetter(props, 'value')
      const store = useStore()
      const wait = useWait()

      const currentTab = ref(0)
      /**
       * @type {import('@vue/composition-api').Ref<Array<{ uuid: string }>>}
       */
      const users = ref([])
      const usersCount = ref(null)
      const errorMessage = ref(null)

      /**
       * @function tabs
       * @returns {Array<{label: any}>}
       */
      const tabs = computed(() => {
        return [{ label: i18n.t('app.labels.users_available') }]
      })

      function close () {
        modalState.value = false
      }

      function getAllUsers () {
        wait.start('fetching users for booking')

        let page = 1
        users.value = []

        const retrieveUsers = () => {
          UserResource.get({
            cid: store.value.getters['auth/getCid']
          }, {
            params: {
              limit: 30,
              page
            }
          })
            .then(response => {
              usersCount.value = response.data.meta.item_count

              const pagination = response.data.meta.pagination
              if (pagination.current_page < pagination.page_count) {
                page += 1
                retrieveUsers()
              } else {
                wait.end('fetching users for booking')
              }

              response.data.items
                .forEach(user => {
                  const newUser = new User(user)
                  users.value.push(newUser)
                })
            }, error => {
              console.log('error get all users', error)
            })
        }
        retrieveUsers()
      }

      /**
       * @function changeOwner
       * @param {{ uuid: string }} user
       */
      function changeOwner (user) {
        wait.start('updating mission owner')
        const missionUuid = store.value.getters['missions/getCurrentMission'].uuid
        let reloadWindows = true
        CompanyMission.changeOwner({
          cid: store.value.getters['auth/getCid'],
          mid: missionUuid
        }, { owner: user.uuid, notify_shipper: false })
          .then(
            () => {
              store.value.dispatch('missions/updateCurrentMissionOwner', { user })
              modalState.value = false
            }, (err) => {
              const data = err.response && err.response.data
              if (data && data.error) {
                const errorMessage = data.error.detail || data.error.title
                reloadWindows = false
                showToaster(null, errorMessage, {
                  type: 'error',
                  position: 'bottom-right'
                })
              }
            }
          )
          .finally(() => {
            wait.end('updating mission owner')
            if (reloadWindows) {
              window.location.reload()
            }
          })
      }

      function initializeDialog () {
        /**
         * Reset the values to their initial value.
         */
        currentTab.value = 0

        getAllUsers()
      }

      onMounted(() => {
        initializeDialog()
      })

      watch(modalState, v => {
        if (v) {
          initializeDialog()
        }
      })

      return {
        getAllUsers,
        modalState,
        currentTab,
        tabs,
        close,
        users,
        usersCount,
        changeOwner,
        errorMessage
      }
    }
  })
</script>

<style lang="scss">
.change-mission-owner-dialog .modal-container {
  height: clamp(300px, 743px, 100%);
}
.change-mission-owner-dialog .ctk-aside-dialog__main, .change-mission-owner-dialog .ctk-aside-dialog__body, .change-mission-owner-dialog .modal-body {
  height: 100%;
}
.change-mission-owner-dialog .choose-owner-form {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0;
  -webkit-flex: 1 1 0;
  flex: 1 1 0;
}
.change-mission-owner-dialog .choose-owner-form__footer {
  margin-top: 0px;
}
.change-mission-owner-dialog .choose-owner-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0;
  -webkit-flex: 1 1 0;
  flex: 1 1 0;
  overflow-y: auto;
  height: calc(100% - 55px);
}
@media (min-width: 770px) {
  .change-mission-owner-dialog .choose-owner-form__footer, .change-mission-owner-dialog .add-owner-form__footer {
    position: relative;
  }
  .change-mission-owner-dialog .choose-owner-form__footer::before, .change-mission-owner-dialog .add-owner-form__footer::before {
    position: absolute;
    content: '';
    left: -2.5rem;
    top: 0;
    width: calc(100% + 5rem);
    height: 10px;
    box-shadow: 0 -2px 2px 0 rgba($secondary-text, 0.25);
  }
}
</style>
