<template>
  <empty-state
    :title="title"
  />
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import EmptyState from '@/views/Carriers/Offers/components/EmptyState/index.vue'

  /**
   * @module component - MissionsEmptyState
   */
  export default defineComponent({
    name: 'MissionsEmptyState',
    components: {
      EmptyState
    },
    data () {
      return {
        title: this.$t('missions.main_content.no_proposal_available')
      }
    }
  })
</script>
