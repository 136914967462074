<template>
  <mission-detail-section
    :id="direction"
    class="mission-detail-direction-section tw-flex tw-flex-col"
  >
    <template
      #title
    >
      <mission-detail-section-title
        :title="$t(direction === 'pickup' ? 'pickup' : 'delivery') | capitalize"
        :reference="directionReference"
      >
        <ui-ctk-icon
          name="marker"
          :class="[direction === 'pickup' ? 'tw-text-pickup' : 'tw-text-delivery']"
          class="tw-text-4xl"
        />
      </mission-detail-section-title>
    </template>

    <mission-detail-group-item
      :title="$t('missions.labels.address')"
      class="tw-mb-5"
      data-test="address"
    >
      <mission-detail-direction-address
        :address="step.address"
      />
    </mission-detail-group-item>

    <mission-detail-specificity-group
      data-test="address-specificities"
    >
      <mission-detail-specificity-item
        v-if="additionalStep"
        :icon="'destination'"
        :title="$t(direction === 'pickup'
          ? 'app.labels.additional_step.pickup'
          : 'app.labels.additional_step.delivery')"
        :variant="direction === 'pickup' ? 'info' : 'primary'"
        :content="additionalStep.comment"
        data-test="additional-step"
      />
      <mission-detail-specificity-item
        v-if="step.comment"
        :icon="'comment'"
        :title="$t('comment')"
        :content="step.comment"
        :variant="direction === 'pickup' ? 'info' : 'primary'"
        data-test="comment"
      />
      <mission-detail-accessibility
        :mission="mission"
        :direction="direction"
        data-test="accessibility"
      />
    </mission-detail-specificity-group>

    <contact-infos
      :mission="mission"
      :direction="direction"
      class="tw-mb-4"
      data-test="contact"
    />

    <mission-detail-timeslots
      :mission="mission"
      :direction="direction"
      data-test="timeslots"
    />

    <mission-detail-specificity-group
      data-test="date-specificities"
    >
      <mission-detail-specificity-item
        v-if="!!rdv"
        :icon="'scheduled'"
        :title="$t(direction === 'pickup'
          ? 'app.labels.rdv_option.pickup'
          : 'app.labels.rdv_option.delivery')"
        :variant="direction === 'pickup' ? 'info' : 'primary'"
        :content="rdv"
        data-test="rdv"
      />
    </mission-detail-specificity-group>

    <mission-detail-options
      :mission="mission"
      :direction="direction"
      data-test="options"
    />
  </mission-detail-section>
</template>

<script>
  import { defineComponent, computed, toRefs } from '@vue/composition-api'

  import MissionDetailGroupItem from '@/views/Carriers/Missions/views/_subs/MissionDetail/components/MissionDetailGroupItem'
  import MissionDetailSection from '@/views/Carriers/Missions/views/_subs/MissionDetail/components/MissionDetailSection'
  import MissionDetailSectionTitle from '@/views/Carriers/Missions/views/_subs/MissionDetail/components/MissionDetailSection/MissionDetailSectionTitle'
  import MissionDetailSpecificityGroup from '@/views/Carriers/Missions/views/_subs/MissionDetail/components/MissionDetailSpecificityGroup'
  import MissionDetailSpecificityItem from '@/views/Carriers/Missions/views/_subs/MissionDetail/components/MissionDetailSpecificityGroup/_subs/MissionDetailSpecificityItem/index.vue'
  import ContactInfos from '@/views/Carriers/Missions/views/_subs/MissionDetail/components/MissionDetailDirectionsSection/_subs/ContactInfos'

  import MissionDetailAccessibility from '../MissionDetailAccessibility'
  import MissionDetailDirectionAddress from '../MissionDetailDirectionAddress'
  import MissionDetailOptions from '../MissionDetailOptions'
  import MissionDetailTimeslots from '../MissionDetailTimeslots'

  export default defineComponent({
    name: 'MissionDetailDirectionSection',
    components: {
      MissionDetailSection,
      MissionDetailSectionTitle,
      MissionDetailGroupItem,
      MissionDetailDirectionAddress,
      MissionDetailOptions,
      MissionDetailTimeslots,
      MissionDetailAccessibility,
      MissionDetailSpecificityGroup,
      MissionDetailSpecificityItem,
      ContactInfos
    },
    props: {
      mission: {
        type: Object,
        required: true
      },
      direction: {
        type: String,
        required: true
      }
    },
    setup (props) {
      const { mission, direction } = toRefs(props)
      const step = computed(() => mission.value[direction.value])

      /**
       * @function getPriceLine
       * @param {string} lineKey
       * @returns {{
       *   key: string
       * } | undefined}
       */
      function getPriceLine (lineKey) {
        if (!mission.value.pricing || !mission.value.pricing.price_lines) return

        // @ts-ignore
        return mission.value.pricing?.price_lines?.find(line => line.key === lineKey)
      }

      const rdv = computed(() => mission.value[direction.value].meeting)
      const additionalStep = computed(() => getPriceLine(direction.value === 'pickup'
        ? 'pickup_additional_step'
        : 'delivery_additional_step'))

      const directionReference = computed(() => mission.value[direction.value].reference)

      return {
        rdv,
        additionalStep,
        step,
        directionReference
      }
    }
  })
</script>

<style lang="scss" scoped>

  .mission-detail-direction-section {
    /* stylelint-disable-next-line */
    &::v-deep {
      .mission-detail-section-title {
        &__icon {
          top: -22px;
        }
      }
    }
  }

</style>
