<template>
  <mission-detail-section
    class="contact-infos"
  >
    <template
      #title
    >
      <mission-detail-section-title
        :title="$tc('missions.mission_detail.label.contact_infos', 2) | capitalize"
      >
        <ui-ctk-icon
          name="phone-help"
          class="tw-text-blue-500 tw-text-4xl"
        />
      </mission-detail-section-title>
    </template>

    <div class="tw-px-4 tw-py-2 tw-flex tw-flex-col tw-bg-gray-200">
      <div
        class="contact-infos__items tw-flex"
        v-if="chronotruckContact"
      >
        <mission-detail-group-item
          :title="$t(`missions.mission_detail.label.contact_agency`) | capitalize"
          class="contacts-infos__item"
          data-test="contact-chronotruck"
        >
          <template>
            <div
              class="tw-text-wrap"
              data-test="contact-chronotruck-email"
            >
              {{ isBoughtByCeva ? chronoInfos.ceva_logistics_france.agency.email : chronotruckContact.contact }}
            </div>
            <div
              class="tw-text-wrap"
              data-test="contact-chronotruck-phone"
            >
              {{ isBoughtByCeva ? chronoInfos.ceva_logistics_france.agency.phone : chronotruckContact.phone }}
            </div>
          </template>
        </mission-detail-group-item>
      </div>
      <div
        class="contact-infos__items tw-flex"
        v-if="freightForwarder"
      >
        <mission-detail-group-item
          :title="$t(`missions.mission_detail.label.contact_freight_forwarder`) | capitalize"
          class="contacts-infos__item"
          data-test="contact-freight-forwarder"
        >
          <template>
            <div data-test="contact-freight-forwarder-name">
              {{ freightForwarder.name }}
            </div>
            <div
              v-if="freightForwarder.email"
              data-test="contact-freight-forwarder-email"
            >
              {{ freightForwarder.email }}
            </div>
            <div
              v-if="freightForwarder.phone"
              data-test="contact-freight-forwarder-phone"
            >
              {{ freightForwarder.phone }}
            </div>
          </template>
        </mission-detail-group-item>
      </div>
    </div>
  </mission-detail-section>
</template>

<script>
  import { computed, defineComponent, toRefs } from '@vue/composition-api'

  import chronoinfos from '@/../config/chronoinfos'

  import MissionDetailSection from '@/views/Carriers/Missions/views/_subs/MissionDetail/components/MissionDetailSection/index.vue'
  import MissionDetailSectionTitle from '@/views/Carriers/Missions/views/_subs/MissionDetail/components/MissionDetailSection/MissionDetailSectionTitle/index.vue'
  import MissionDetailGroupItem from '@/views/Carriers/Missions/views/_subs/MissionDetail/components/MissionDetailGroupItem/index.vue'

  /**
   * @module component - ContactInfos
   */
  export default defineComponent({
    name: 'ContactInfos',
    components: {
      MissionDetailSection,
      MissionDetailSectionTitle,
      MissionDetailGroupItem
    },
    props: {
      mission: {
        type: Object,
        required: true
      },
      boughtBy: {
        type: String,
        default: 'chronotruck_sa'
      }
    },
    data () {
      return {
        chronoInfos: chronoinfos
      }
    },
    computed: {
      isBoughtByCeva () {
        return this.boughtBy === 'ceva_logistics_france'
      }
    },
    setup (props) {
      const { mission } = toRefs(props)

      const chronotruckContact = computed(() => mission.value ? mission.value.shipper : undefined)
      const freightForwarder = computed(() => mission.value ? mission.value.freight_forwarder : undefined)

      return {
        chronotruckContact,
        freightForwarder
      }
    }
  })
</script>

<style lang="scss" scoped>
.contact-infos {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  white-space: nowrap;
}
.contact-infos__items {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  height: 100px;
  position: relative;
  grid-gap: 1rem;
  gap: 1rem;
}
.contact-infos__items .mission-detail-group-item {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  -ms-flex-negative: 0;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
}
@media (min-width: 770px) {
  .contact-infos__items .mission-detail-group-item {
    flex: 1 0 calc(18% - 1rem);
  }
}
.contacts-infos__item {
  height: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
</style>
