export default class User {
  constructor ({
    uuid,
    status,
    first_name: firstName,
    last_name: lastName,
    phone,
    email
  }) {
    this.uuid = uuid
    this.status = status
    this.first_name = firstName
    this.last_name = lastName
    this.phone = phone
    this.email = email
  }

  get fullName () {
    return `${this.first_name ? this.first_name.trim() : ''} ${this.last_name ? this.last_name?.trim() : ''}`
  }
}
