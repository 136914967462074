<template>
  <div class="mission-detail-section tw-bg-white tw-pl-10 tw-pr-6 md:tw-pr-10">
    <div
      class="mission-detail-section__title tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-between lg:tw-items-center"
    >
      <slot
        name="title"
      />
    </div>
    <slot />
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  /**
   * @module component - MissionDetailSection
   */
  export default defineComponent({
    name: 'MissionDetailSection'
  })
</script>
