<template>
  <div class="mission-detail-price-section">
    <mission-detail-pricing-section
      v-if="billing"
      :billing="billing"
    />
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import MissionDetailPricingSection from './_subs/MissionDetailPricingSection'

  /**
   * @module component - MissionDetailPriceSection
   */
  export default defineComponent({
    name: 'MissionDetailPriceSection',
    components: {
      MissionDetailPricingSection
    },
    props: {
      billing: {
        type: Object,
        default: null
      }
    }
  })
</script>

<style lang="scss" scoped>
.mission-detail-price-section {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 0.25rem;
}
.mission-detail-price-section .mission-detail-pricing-section {
  padding-left: 0px;
  padding-right: 0px;
  position: relative;
}
.mission-detail-price-section::v-deep .mission-detail-section-title {
  min-height: 45px;
  margin: 0;
}
.mission-detail-price-section::v-deep .mission-detail-section-title__icon {
  top: -22px;
}
</style>
