<template>
  <mission-detail-group-item
    v-if="hasTailLift || hasHandling"
    class="mission-detail-options"
    :title="$t('offers.labels.options')"
  >
    <mission-detail-specificity-group
      data-test="group"
    >
      <mission-detail-specificity-item
        v-if="hasTailLift"
        :icon="'hatch'"
        :title="$t('app.labels.vehicle_options')"
        :content="$t('app.values.vehicle_tail_lift')"
        :variant="direction === 'pickup' ? 'info' : 'primary'"
        data-test="tail-lift"
      />
      <mission-detail-specificity-item
        v-if="hasHandling"
        :icon="'logistic'"
        :title="$t('app.labels.handling')"
        :content="$t('handling_by_the_driver')"
        :variant="direction === 'pickup' ? 'info' : 'primary'"
        data-test="handling"
      />
    </mission-detail-specificity-group>
  </mission-detail-group-item>
</template>

<script>
  import { defineComponent, computed, toRefs } from '@vue/composition-api'

  import MissionDetailGroupItem from '@/views/Carriers/Missions/views/_subs/MissionDetail/components/MissionDetailGroupItem'
  import MissionDetailSpecificityGroup from '@/views/Carriers/Missions/views/_subs/MissionDetail/components/MissionDetailSpecificityGroup'
  import MissionDetailSpecificityItem from '@/views/Carriers/Missions/views/_subs/MissionDetail/components/MissionDetailSpecificityGroup/_subs/MissionDetailSpecificityItem/index.vue'

  export default defineComponent({
    name: 'MissionDetailOptions',
    components: {
      MissionDetailGroupItem,
      MissionDetailSpecificityGroup,
      MissionDetailSpecificityItem
    },
    props: {
      mission: {
        type: Object,
        required: true
      },
      direction: {
        type: String,
        required: true
      }
    },
    setup (props) {
      const { mission, direction } = toRefs(props)
      const hasHandling = computed(() => mission.value[direction.value].handling.driver)
      const hasTailLift = computed(() => mission.value[direction.value].handling.tail_lift)

      return {
        hasHandling,
        hasTailLift
      }
    }
  })
</script>

<style lang="scss" scoped>
.mission-detail-options .mission-detail-specificity-group {
  margin-bottom: 0px;
}
</style>
