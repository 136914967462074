<template>
  <div class="tw-flex tw-flex-col tw-items-center tw-border tw-border-solid tw-border-gray-500 tw-p-3 tw-text-center">
    <div
      v-text="$n(pallet.quantity)"
      class="tw-font-medium tw-text-base tw-text-secondary"
      data-test="quantity"
    />
    <div
      class="tw-uppercase tw-text-secondary-text tw-text-xs tw-font-normal"
      data-test="format"
    >
      {{ $tc('pallet', pallet.quantity) }} {{ format }}
    </div>
  </div>
</template>

<script>
  import { computed, defineComponent, toRefs } from '@vue/composition-api'

  export default defineComponent({
    name: 'GoodsPallet',
    props: {
      pallet: {
        type: Object,
        required: true
      }
    },
    setup (props) {
      const { pallet } = toRefs(props)
      // @ts-ignore
      const format = computed(() => `${pallet.value.width}x${pallet.value.length}`)

      return {
        format
      }
    }
  })
</script>
