<template>
  <mission-detail-section
    class="follow-up"
  >
    <template
      #title
    >
      <mission-detail-section-title
        :title="$t('missions.mission_detail.label.follow_up') | capitalize"
      >
        <ui-ctk-icon
          name="arrival"
          class="tw-text-blue-500 tw-text-xl"
        />
      </mission-detail-section-title>
    </template>

    <template
      #default
    >
      <div
        class="follow-up__explanation"
        :class="{ 'tw-mb-30px': showPodUpload }"
        data-test="explanations"
      >
        <p
          v-if="isPlanned"
          class="tw-leading-4 tw-whitespace-normal"
          v-text="$t('missions.detail.followup.paragraphs.planned')"
        />

        <p
          v-else-if="showNextStepButton"
          class="tw-leading-4 tw-whitespace-normal tw-m-0"
          v-text="$t('missions.detail.followup.paragraphs.in_progress')"
        />

        <template
          v-else-if="isCompleted"
        >
          <p
            v-text="$t('missions.detail.followup.title.completed')"
            class="tw-text-blue-500 tw-font-medium tw-whitespace-normal"
          />
          <p
            v-text="$t('missions.detail.followup.paragraphs.completed')"
            class="tw-leading-4 tw-whitespace-normal"
          />
        </template>

        <div
          v-if="showPodUpload"
        >
          <proof-of-delivery-upload-help-button
            icon="info"
            placement="top"
            color="inherit"
            hover-color="inherit"
            font-size="sm"
            tooltip-path="missions.explanation_pod_file"
            text-position="reverse"
            :raw-text="podExplanationText"
          />
        </div>
      </div>

      <div
        class="follow-up_actions tw-flex tw-flex-col tw-gap-12px tw-mt-5 xl:tw-flex-row-reverse xl:tw-absolute xl:tw-top-0 xl:tw-right-0 xl:tw-my-0"
      >
        <ui-button
          v-if="showNextStepButton"
          :loading="wait.is('loading direction step')"
          :disabled="wait.is('loading direction step')"
          variant="primary"
          type="button"
          class="direction-step__button"
          @click="nextStep"
        >
          {{ $t((missionPickupState === 'pending' || missionPickupState === 'late')
            ? 'missions.pickup_done'
            : 'missions.delivery_done'
          ) }}
        </ui-button>

        <ctk-btn-file
          id="btn-download-mission-order"
          :url="urlMissionOrder"
          :popover-title="$t('missions.mission_order_title')"
          :file-name="`mission-order-${missionReference}.pdf`"
        >
          <ui-button
            :rounded="false"
            variant="info"
            outline
            block
            class="tw-w-full"
          >
            {{ $t('missions.buttons.download_caf') | capitalize }}
          </ui-button>
        </ctk-btn-file>
      </div>

      <div
        v-if="['completed', 'late'].includes(missionDeliveryState) && ['pending', 'available'].includes(missionPodState)"
      >
        <proof-of-delivery
          class="tw-mt-5 xl:tw-mb-0"
        />
      </div>
    </template>
  </mission-detail-section>
</template>

<script>
  import { computed, defineComponent, toRefs } from '@vue/composition-api'

  import { showToaster } from '@/services/Toaster'

  import useI18n from '@/composables/useI18n'
  import useStore from '@/composables/useStore'
  import useWait from '@/composables/useWait'

  import CtkBtnFile from '@/components/CtkBtnFile/index.vue'
  import MissionDetailSection from '@/views/Carriers/Missions/views/_subs/MissionDetail/components/MissionDetailSection/index.vue'
  import MissionDetailSectionTitle from '@/views/Carriers/Missions/views/_subs/MissionDetail/components/MissionDetailSection/MissionDetailSectionTitle/index.vue'
  import MissionDetailGroupItem from '@/views/Carriers/Missions/views/_subs/MissionDetail/components/MissionDetailGroupItem/index.vue'
  import ProofOfDelivery from '@/views/Carriers/Missions/views/_subs/MissionDetail/_subs/ProofOfDelivery/index.vue'
  import ProofOfDeliveryUploadHelpButton from '@/views/Carriers/Missions/views/_subs/MissionDetail/_subs/ProofOfDelivery/_subs/ProofOfDeliveryUpload/_subs/ProofOfDeliveryUploadHelpButton/index.vue'

  /**
   * @module component - ReferencesInfos
   */
  export default defineComponent({
    name: 'FollowUpStatus',
    components: {
      CtkBtnFile,
      MissionDetailSection,
      MissionDetailSectionTitle,
      MissionDetailGroupItem,
      ProofOfDelivery,
      ProofOfDeliveryUploadHelpButton
    },
    props: {
      mission: {
        type: Object,
        required: true
      }
    },
    setup (props) {
      const { mission } = toRefs(props)
      const store = useStore()
      const wait = useWait()
      const i18n = useI18n()

      const urlMissionOrder = computed(() => {
        const cid = store.value.getters['auth/getCid']
        return `v2/companies/${cid}/missions/${mission.value.uuid}/mission-order`
      })

      // @ts-ignore
      const missionReference = computed(() => mission?.value?.reference)

      // @ts-ignore
      const missionState = computed(() => mission?.value?.state)

      // @ts-ignore
      const missionPickupState = computed(() => mission?.value?.pickup?.state)

      // @ts-ignore
      const missionDeliveryState = computed(() => mission?.value?.delivery?.state)

      // @ts-ignore
      const missionPodState = computed(() => mission?.value?.proof_of_delivery?.state)

      // @ts-ignore
      const isPlanned = computed(() => mission?.value?.state === 'planned')

      // @ts-ignore
      const isCmrPending = computed(() => mission?.value?.proof_of_delivery?.state === 'pending')

      // @ts-ignore
      const isCompleted = computed(() => mission?.value?.state === 'completed' || mission?.value?.proof_of_delivery?.state === 'available')

      // @ts-ignore
      const isLimitExpired = computed(() => isCmrPending?.value && mission?.value?.delivery?.state === 'late')

      // @ts-ignore
      const showNextStepButton = computed(() => !isLimitExpired?.value && (missionState?.value === 'in_progress' && missionDeliveryState?.value !== 'completed'))

      // @ts-ignore
      const showPodUpload = computed(() => ['completed', 'late'].includes(missionDeliveryState?.value) && missionPodState?.value === 'pending')

      // @ts-ignore
      const podExplanationText = computed(() => {
        const pod = i18n.value.t('missions.proof_of_delivery_explanation_text_now.pod')
        return i18n.value.t('missions.proof_of_delivery_explanation_text_now', { pod })
      })

      const nextStep = () => {
        wait.start('loading direction step')
        store.value.dispatch('missions/companyMissionActions')
          .catch(err => {
            if (!err.response) return

            const { data } = err.response
            if (data && data.error) {
              const errorMessage = data.error.detail || data.error.title
              showToaster(this, errorMessage, {
                type: 'error',
                position: 'bottom-right'
              })
            }
          })
          .finally(() => {
            wait.end('loading direction step')
          })
      }

      return {
        urlMissionOrder,
        missionReference,
        missionState,
        missionPickupState,
        missionDeliveryState,
        missionPodState,
        isPlanned,
        isCmrPending,
        isCompleted,
        isLimitExpired,
        showNextStepButton,
        showPodUpload,
        podExplanationText,
        wait,
        nextStep
      }
    }
  })
</script>

<style lang="scss" scoped>
.follow-up {
  position: relative;
  white-space: nowrap;
}
.follow-up__explanation {
  width: 100%;
  letter-spacing: 0.28px !important;
}
@media (max-width: 769.9px) {
  .follow-up__explanation {
    width: min(530px, 100%);
  }
}
.follow-up .direction-step__button {
  height: 35px;
}
</style>
