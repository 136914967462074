<template>
  <div class="proof-of-delivery tw-h-full tw-flex-1 tw-flex">
    <proof-of-delivery-upload
      v-if="isPODPending"
    />

    <div
      v-if="isPODAvailable && !isPODPending"
      class="tw-w-full tw-flex-col tw-justify-start tw-items-center tw-gap-4"
    >
      <proof-of-delivery-view
        class="tw-mt-4"
      />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import ProofOfDeliveryUpload from './_subs/ProofOfDeliveryUpload'
  import ProofOfDeliveryView from './_subs/ProofOfDeliveryView'

  /**
   * @module component - proofOfDelivery
   */
  export default {
    name: 'ProofOfDelivery',
    components: {
      ProofOfDeliveryUpload,
      ProofOfDeliveryView
    },
    computed: {
      ...mapGetters('missions', [
        'getCurrentMission'
      ]),
      /**
       * @function isPODPending
       */
      isPODPending () {
        return (this.getCurrentMission.proof_of_delivery && this.getCurrentMission.proof_of_delivery.state === 'pending')
      },
      isPODAvailable () {
        return this.getCurrentMission.proof_of_delivery.state === 'available'
      }
    }
  }
</script>
